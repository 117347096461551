import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  updateSuccess(element, reflex) {
   element.innerText = 'Updated Successfully.'
  }

}
