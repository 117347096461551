// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("local-time").start()
import "chartkick/highcharts"



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import 'jquery/dist/jquery.slim'
window.$ = window.jQuery = require('jquery')
import 'popper.js/dist/esm/popper'
import 'bootstrap/dist/js/bootstrap.bundle'
import flatpickr from "flatpickr"
import "vanilla/flatpickr.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()